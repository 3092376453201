import polygonImgOne from '../../img/polygon-img-one.png';
import polygonImgTwo from '../../img/polygon-img-two.png';
import polygonImgThree from '../../img/polygon-img-three.png';
import './TitleSection.css';

function TitleSection() {
  return (
    <div className="titleSection">
      <h1 className="titleSection__title">ПроАвтоматизация</h1>
      <p className="titleSection__text">
        Комплексная автоматизация производства
      </p>
      <img
        src={polygonImgOne}
        alt="Шестиугольное изображение оранжевого роботизированного станка"
        className="titleSection__img-one"
      />
      <img
        src={polygonImgTwo}
        alt="Резка листа металла с большим колличеством оранжевых искр"
        className="titleSection__img-two"
      />
      <img
        src={polygonImgThree}
        alt="Дисплей компьютера с большим множеством схем и кнопок"
        className="titleSection__img-three"
      />
    </div>
  );
}

export default TitleSection;
