import purposeSectionPolygon from '../../img/purposeSection-polygon.png';
import weOfferSectionCardImgOne from '../../img/weOfferSection-card-img-one.png';
import weOfferSectionCardImgTwo from '../../img/weOfferSection-card-img-two.png';
import weOfferSectionCardImgThree from '../../img/weOfferSection-card-img-three.png';
import './WeOfferSection.css';

function WeOfferSection() {
  return (
    <div className="weOfferSection" id="weOfferSection">
      <div className="weOfferSection__container">
        <h2 className="weOfferSection__title">Мы предлагаем</h2>
        <p className="weOfferSection__text">
          комплексные решения: проектирование и&nbsp;внедрение систем АСУ
          ТП&nbsp;для промышленных предприятий любой сложности.
        </p>
      </div>
      <div className="weOfferSection__cards">
        <div className="weOfferSection__card">
          <div className="weOfferSection__card-all-content">
            <img
              src={weOfferSectionCardImgOne}
              alt="Фото завода с множеством труб"
              className="weOfferSection__card-img"
            />
            <div className="weOfferSection__card-text-content">
              <h3 className="weOfferSection__card-title">
                Автоматизация &laquo;Под ключ&raquo;
              </h3>
              <p className="weOfferSection__card-text">
                автоматизация промышленных объектов, от&nbsp;разаработки
                и&nbsp;внедрения до&nbsp;гарантийного и&nbsp;послегарантийного
                обслуживания
              </p>
            </div>
          </div>
        </div>
        <div className="weOfferSection__card">
          <div className="weOfferSection__card-all-content">
            <img
              src={weOfferSectionCardImgTwo}
              alt="Фото завода с множеством труб"
              className="weOfferSection__card-img"
            />
            <div className="weOfferSection__card-text-content">
              <h3 className="weOfferSection__card-title">
                Автоматизация систем контроля
              </h3>
              <p className="weOfferSection__card-text">
                проектирование и&nbsp;внедрение систем автоматического контроля
                на&nbsp;промышленных предприятиях; визуализация систем
                автоматического регулирования и&nbsp;систем управления
                технологическими процессами
              </p>
            </div>
          </div>
        </div>
        <div className="weOfferSection__card">
          <div className="weOfferSection__card-all-content">
            <img
              src={weOfferSectionCardImgThree}
              alt="Фото завода с множеством труб"
              className="weOfferSection__card-img"
            />
            <div className="weOfferSection__card-text-content">
              <h3 className="weOfferSection__card-title">
                Разработка программного обеспечения
              </h3>
              <p className="weOfferSection__card-text">
                -разработка программного обеспечения АСУ ТП
              </p>
              <p className="weOfferSection__card-text">
                -разработка аппаратного обеспечения для нетиповых модулей
                АСУ&nbsp;ТП
              </p>
            </div>
          </div>
        </div>
      </div>
      <img
        src={purposeSectionPolygon}
        alt="Изображение бордового полигона"
        className="weOfferSection__polygon"
      />
    </div>
  );
}

export default WeOfferSection;
