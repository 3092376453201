import ourAdvantagesSectionCardIcoOne from '../../img/ourAdvantagesSection-card-ico-one.png';
import ourAdvantagesSectionCardIcoTwo from '../../img/ourAdvantagesSection-card-ico-two.png';
import ourAdvantagesSectionCardIcoThree from '../../img/ourAdvantagesSection-card-ico-three.png';
import ourAdvantagesSectionCardIcoFour from '../../img/ourAdvantagesSection-card-ico-four.png';
import ourAdvantagesSectionCardIcoFive from '../../img/ourAdvantagesSection-card-ico-five.png';
import ourAdvantagesSectionCardIcoSix from '../../img/ourAdvantagesSection-card-ico-six.png';
import './OurAdvantagesSection.css';

function OurAdvantagesSection() {
  return (
    <div className="ourAdvantagesSection" id="ourAdvantagesSection">
      <div className="ourAdvantagesSection__container">
        <h2 className="ourAdvantagesSection__title">Наши преимущества</h2>
        <p className="ourAdvantagesSection__text">
          В&nbsp;данной отрасли наша компания является одним из&nbsp;ведущих
          поставщиков современных программно-технических средств,
          предназначенных для АСУТП.
        </p>
      </div>
      <div className="ourAdvantagesSection__cards-container">
        <div className="ourAdvantagesSection__cards">
          <div className="ourAdvantagesSection__card">
            <img
              src={ourAdvantagesSectionCardIcoOne}
              alt="Красная иконка чертежа со шестеренкой и карандашом"
              className="ourAdvantagesSection__card-ico"
            />
            <h3 className="ourAdvantagesSection__card-title">компелкс</h3>
            <p className="ourAdvantagesSection__card-texts">
              от&nbsp;разработки проекта до&nbsp;сдачи объекта под ключ
            </p>
          </div>
          <div className="ourAdvantagesSection__card">
            <img
              src={ourAdvantagesSectionCardIcoTwo}
              alt="Красная иконка чертежа со шестеренкой и карандашом"
              className="ourAdvantagesSection__card-ico"
            />
            <h3 className="ourAdvantagesSection__card-title">команда</h3>
            <p className="ourAdvantagesSection__card-texts">
              узко-профильные специалисты высокого класса
            </p>
          </div>
          <div className="ourAdvantagesSection__card">
            <img
              src={ourAdvantagesSectionCardIcoThree}
              alt="Красная иконка чертежа со шестеренкой и карандашом"
              className="ourAdvantagesSection__card-ico"
            />
            <h3 className="ourAdvantagesSection__card-title">Качество</h3>
            <p className="ourAdvantagesSection__card-texts">
              наличие лицензий и сертификатов
            </p>
          </div>
          <div className="ourAdvantagesSection__card">
            <img
              src={ourAdvantagesSectionCardIcoFour}
              alt="Красная иконка чертежа со шестеренкой и карандашом"
              className="ourAdvantagesSection__card-ico"
            />
            <h3 className="ourAdvantagesSection__card-title">Оборудование</h3>
            <p className="ourAdvantagesSection__card-texts">
              лучшее современное оборудование в Росии
            </p>
          </div>
          <div className="ourAdvantagesSection__card">
            <img
              src={ourAdvantagesSectionCardIcoFive}
              alt="Красная иконка чертежа со шестеренкой и карандашом"
              className="ourAdvantagesSection__card-ico"
            />
            <h3 className="ourAdvantagesSection__card-title">Партнерство</h3>
            <p className="ourAdvantagesSection__card-texts">
              гибкий подход при реализации потребностей заказчика
            </p>
          </div>
          <div className="ourAdvantagesSection__card">
            <img
              src={ourAdvantagesSectionCardIcoSix}
              alt="Красная иконка чертежа со шестеренкой и карандашом"
              className="ourAdvantagesSection__card-ico"
            />
            <h3 className="ourAdvantagesSection__card-title">поставщики</h3>
            <p className="ourAdvantagesSection__card-texts">
              обширный пул поставщиков компонентов
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurAdvantagesSection;
