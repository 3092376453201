import whereUsedSectionCardImgOne from '../../img/whereUsedSection-card-img-one.png';
import whereUsedSectionCardIcoOne from '../../img/whereUsedSection-card-ico-one.png';
import whereUsedSectionCardImgTwo from '../../img/whereUsedSection-card-img-two.png';
import whereUsedSectionCardIcoTwo from '../../img/whereUsedSection-card-ico-two.png';
import whereUsedSectionCardImgThree from '../../img/whereUsedSection-card-img-three.png';
import whereUsedSectionCardIcoThree from '../../img/whereUsedSection-card-ico-three.png';
import whereUsedSectionCardImgFour from '../../img/whereUsedSection-card-img-four.png';
import whereUsedSectionCardIcoFour from '../../img/whereUsedSection-card-ico-four.png';
import whereUsedSectionCardImgFive from '../../img/whereUsedSection-card-img-five.png';
import whereUsedSectionCardIcoFive from '../../img/whereUsedSection-card-ico-five.png';
import whereUsedSectionCardImgSix from '../../img/whereUsedSection-card-img-six.png';
import whereUsedSectionCardIcoSix from '../../img/whereUsedSection-card-ico-six.png';
import './WhereUsedSection.css';

function WhereUsedSection() {
  return (
    <div className="whereUsedSection" id="whereUsedSection">
      <div className="whereUsedSection__container">
        <h2 className="whereUsedSection__title">Где применяют АСУ</h2>
        <p className="whereUsedSection__text">
          Наша компания является одним из&nbsp;ведущих поставщиков современных
          программно-технических средств, предназначенных для АСУ
          ТП&nbsp;в&nbsp;любой отрасли.
        </p>
      </div>
      <div className="whereUsedSection__cards-container">
        <div className="whereUsedSection__cards">
          <div className="whereUsedSection__card">
            <img
              src={whereUsedSectionCardImgOne}
              alt="Затемненное фото нефтедобывающей установки"
              className="whereUsedSection__card-img"
            />
            <img
              src={whereUsedSectionCardIcoOne}
              alt="Белая иконка нефтедобывающей установки"
              className="whereUsedSection__card-ico"
            />
            <p className="whereUsedSection__card-texts">
              Нефтегазовая промышленность
            </p>
          </div>
          <div className="whereUsedSection__card">
            <img
              src={whereUsedSectionCardImgTwo}
              alt="Затемненное фото заводской площадки с группой людей в белых костюмах с капюшонами и масками"
              className="whereUsedSection__card-img"
            />
            <img
              src={whereUsedSectionCardIcoTwo}
              alt="Белая иконка соедененных между собой цистерн"
              className="whereUsedSection__card-ico"
            />
            <p className="whereUsedSection__card-texts">
              химическая промышленность
            </p>
          </div>
          <div className="whereUsedSection__card">
            <img
              src={whereUsedSectionCardImgThree}
              alt="Затемненное фото атомной станции"
              className="whereUsedSection__card-img-broken"
            />
            <img
              src={whereUsedSectionCardIcoThree}
              alt="Белая иконка атомной станции"
              className="whereUsedSection__card-ico"
            />
            <p className="whereUsedSection__card-texts">
              атомная промышленность
            </p>
          </div>
          <div className="whereUsedSection__card">
            <img
              src={whereUsedSectionCardImgFour}
              alt="Затемненное фото людей работающий на конвейере"
              className="whereUsedSection__card-img"
            />
            <img
              src={whereUsedSectionCardIcoFour}
              alt="Белая иконка на которой изображен дом, баки, облака и ветрянная мелница"
              className="whereUsedSection__card-ico"
            />
            <p className="whereUsedSection__card-texts">
              пищевая промышленность
            </p>
          </div>
          <div className="whereUsedSection__card">
            <img
              src={whereUsedSectionCardImgFive}
              alt="Затемненное изображение промышленной вышки"
              className="whereUsedSection__card-img"
            />
            <img
              src={whereUsedSectionCardIcoFive}
              alt="Белая иконка баков"
              className="whereUsedSection__card-ico"
            />
            <p className="whereUsedSection__card-texts">
              тяжелая промышленность
            </p>
          </div>
          <div className="whereUsedSection__card">
            <img
              src={whereUsedSectionCardImgSix}
              alt="Затемненное фото конвейера с нитями"
              className="whereUsedSection__card-img"
            />
            <img
              src={whereUsedSectionCardIcoSix}
              alt="Белая иконка высокотехнологичного станка"
              className="whereUsedSection__card-ico"
            />
            <p className="whereUsedSection__card-texts">
              легкая промышленность
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhereUsedSection;
