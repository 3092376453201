import HeaderLogo from '../HeaderLogo/HeaderLogo.js';
import HeaderMail from '../HeaderMail/HeaderMail.js';
import HeaderPhone from '../HeaderPhone/HeaderPhone.js';
import './Header.css';

function Header() {
  return (
    <header className="header">
      <HeaderLogo />
      <div className="header-container">
        <HeaderMail />
        <HeaderPhone />
      </div>
    </header>
  );
}

export default Header;
