import phoneIco from '../../img/phone-ico.png';
import './HeaderPhone.css';

function HeaderPhone() {
  return (
    <a
      className="header__phone-container"
      href="tel:+78462210444"
      target="_blanck"
    >
      <img
        src={phoneIco}
        alt="Иконка в виде телефонной трубки"
        className="header__phone-ico"
      />
      <p className="header__phone-text">8 (846) 22-10-444</p>
    </a>
  );
}

export default HeaderPhone;
