import ourContactsSectionMailIco from '../../img/ourContactsSection-mail-ico.png';
import ourContactsSectionPhoneIco from '../../img/ourContactsSection-phone-ico.png';
import ourContactsSectionAdressIco from '../../img/ourContactsSection-adress-ico.png';
import ourContactsSectionBgFigure from '../../img/ourContactsSection-bg-figure.png';
import './OurContactsSection.css';

function OurContactsSection() {
  return (
    <div className="ourContactsSection" id="ourContactsSection">
      <div className="ourContactsSection__container">
        <h2 className="ourContactsSection__title">Наши контакты</h2>
        <p className="ourContactsSection__text">
          Головной офис компании находится в&nbsp;г. Самара.
        </p>
        <p className="ourContactsSection__text">
          Связаться с&nbsp;нами можно по&nbsp;указанным контактам.
        </p>
      </div>
      <div className="ourContactsSection__contacts-containers">
        <a
          className="ourContactsSection__contacts-mail"
          href="mailto:info@automatika.pro"
          target="_blanck"
        >
          <img
            src={ourContactsSectionMailIco}
            alt="Иконка красного коверта"
            className="ourContactsSection__contacts-mail-img"
          />
          <p className="ourContactsSection__contacts-mail-text">
            INFO@AUTOMATIKA.PRO
          </p>
        </a>
        <a
          className="ourContactsSection__contacts-phone"
          href="tel:+78462210444"
          target="_blanck"
        >
          <img
            src={ourContactsSectionPhoneIco}
            alt="Иконка красного телефона"
            className="ourContactsSection__contacts-phone-img"
          />
          <p className="ourContactsSection__contacts-phone-text">
            8 (846) 22-10-444
          </p>
        </a>
        <a
          className="ourContactsSection__contacts-adress"
          href="https://2gis.ru/samara/search/%D0%B3.%20%D0%A1%D0%B0%D0%BC%D0%B0%D1%80%D0%B0%2C%20%D1%83%D0%BB.%20%D0%9D%D0%B5%D0%B2%D1%81%D0%BA%D0%B0%D1%8F%20%D0%B4.3%2C%20%D0%BE%D1%84%D0%B8%D1%81%20105?m=50.125832%2C53.210532%2F17.71"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={ourContactsSectionAdressIco}
            alt="Иконка красной геометки"
            className="ourContactsSection__contacts-adress-img"
          />
          <p className="ourContactsSection__contacts-adress-text">
            443100, г. Самара, ул. Невская д.3, офис 105
          </p>
        </a>
      </div>
      <a
        href="https://2gis.ru/samara/search/%D0%B3.%20%D0%A1%D0%B0%D0%BC%D0%B0%D1%80%D0%B0%2C%20%D1%83%D0%BB.%20%D0%9D%D0%B5%D0%B2%D1%81%D0%BA%D0%B0%D1%8F%20%D0%B4.3%2C%20%D0%BE%D1%84%D0%B8%D1%81%20105?m=50.125832%2C53.210532%2F17.71"
        target="_blank"
        rel="noreferrer"
      >
        <div className="ourContactsSection__map"></div>
      </a>
      <img
        src={ourContactsSectionBgFigure}
        alt="Розовая фоновая фигура"
        className="ourContactsSectio__bg-figure"
      />
    </div>
  );
}

export default OurContactsSection;
