import logoImg from '../../img/logo-header-img.png';
import logoText from '../../img/header-logo-text-img.png';
import './HeaderLogo.css';

function HeaderLogo() {
  return (
    <div className="header__logo-container">
      <img
        src={logoImg}
        alt="Логотип ПроАвтоматизация"
        className="header__logo-img"
      />
      <img
        src={logoText}
        alt="Текст логотипа ПроАвтоматизация"
        className="header__logo-text"
      />
    </div>
  );
}

export default HeaderLogo;
