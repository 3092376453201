import './StagesSection.css';

function StagesSection() {
  return (
    <div className="stagesSection" id="stagesSection">
      <div className="stagesSection__container">
        <h2 className="stagesSection__title">Этапы работ по проекту</h2>
        <p className="stagesSection__text">
          Компания предоставляет клиентам полный перечень работ, включая
          проектировочные чертежи и&nbsp;полный пакет документации.
        </p>
      </div>
      <div className="stagesSection__scheme">
        <div className="stagesSection__scheme-part-container">
          <div className="stagesSection__scheme-part-content">
            <p className="stagesSection__scheme-part-text">
              Ознакомление с&nbsp;объетом, изучение документации
            </p>
            <div className="stagesSection__scheme-part-number-one"></div>
          </div>
        </div>
        <div className="stagesSection__scheme-part-container">
          <div className="stagesSection__scheme-part-content-revers">
            <p className="stagesSection__scheme-part-text-revers">
              Разработка ТЗ&nbsp;совместно с&nbsp;заказчиком
            </p>
            <div className="stagesSection__scheme-part-number-two-revers"></div>
          </div>
        </div>
        <div className="stagesSection__scheme-part-container">
          <div className="stagesSection__scheme-part-content">
            <p className="stagesSection__scheme-part-text">
              Составление структуры проекта
            </p>
            <div className="stagesSection__scheme-part-number-three"></div>
          </div>
        </div>
        <div className="stagesSection__scheme-part-container">
          <div className="stagesSection__scheme-part-content-revers-short">
            <p className="stagesSection__scheme-part-text-revers">
              Составление чертежей
            </p>
            <div className="stagesSection__scheme-part-number-four-revers"></div>
          </div>
        </div>
        <div className="stagesSection__scheme-part-container">
          <div className="stagesSection__scheme-part-content">
            <p className="stagesSection__scheme-part-text">
              Разработка шкафов автоматизации
            </p>
            <div className="stagesSection__scheme-part-number-five"></div>
          </div>
        </div>
        <div className="stagesSection__scheme-part-container">
          <div className="stagesSection__scheme-part-content-revers-short-two">
            <p className="stagesSection__scheme-part-text-revers">
              Подготовка спецификаций
            </p>
            <div className="stagesSection__scheme-part-number-six-revers"></div>
          </div>
        </div>
        <div className="stagesSection__scheme-part-container">
          <div className="stagesSection__scheme-part-content">
            <p className="stagesSection__scheme-part-text">
              Этап тестирования, пояснительная записка
            </p>
            <div className="stagesSection__scheme-part-number-seven"></div>
          </div>
        </div>
        <div className="stagesSection__scheme-part-container-eight">
          <div className="stagesSection__scheme-part-content-revers">
            <p className="stagesSection__scheme-part-text-revers">
              Разработка программ для контроллеров
            </p>
            <div className="stagesSection__scheme-part-number-eight-revers"></div>
          </div>
        </div>
        <div className="stagesSection__scheme-part-container">
          <div className="stagesSection__scheme-part-content">
            <p className="stagesSection__scheme-part-text">
              Разработка диспетчеризации и&nbsp;программирования
            </p>
            <div className="stagesSection__scheme-part-number-nine"></div>
          </div>
        </div>
        <div className="stagesSection__scheme-bg-container">
          <div className="stagesSection__scheme-bg-img"></div>
          <div className="stagesSection__scheme-bg-img"></div>
          <div className="stagesSection__scheme-bg-img"></div>
          <div className="stagesSection__scheme-bg-img"></div>
          <div className="stagesSection__scheme-bg-img"></div>
          <div className="stagesSection__scheme-bg-img"></div>
          <div className="stagesSection__scheme-bg-img"></div>
          <div className="stagesSection__scheme-bg-img"></div>
          <div className="stagesSection__scheme-bg-img"></div>
          <div className="stagesSection__scheme-bg-img-end"></div>
        </div>
      </div>
    </div>
  );
}

export default StagesSection;
