import emailIco from '../../img/email-ico.png';
import './HeaderMail.css';

function HeaderMail() {
  return (
    <a
      className="header__email-container"
      href="mailto:info@automatika.pro"
      target="_blanck"
    >
      <img
        src={emailIco}
        alt="Иконка в виде почтового конверта"
        className="header__email-ico"
      />
      <p className="header__email-text">INFO@AUTOMATIKA.PRO</p>
    </a>
  );
}

export default HeaderMail;
