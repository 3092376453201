import Header from '../../Header/Header.js';
import TitleSection from '../../TitleSection/TitleSection.js';
import PurposeSection from '../../PurposeSection/PurposeSection.js';
import WeOfferSection from '../../WeOfferSection/WeOfferSection.js';
import ProsSection from '../../ProsSection/ProsSection.js';
import WhereUsedSection from '../../WhereUsedSection/WhereUsedSection.js';
import StagesSection from '../../StagesSection/StagesSection.js';
import OurAdvantagesSection from '../../OurAdvantagesSection/OurAdvantagesSection.js';
import OurContactsSection from '../../OurContactsSection/OurContactsSection.js';
import Footer from '../../Footer/Footer.js';

function MainPage() {
  return (
    <>
      <Header />
      <TitleSection />
      <PurposeSection />
      <WeOfferSection />
      <ProsSection />
      <WhereUsedSection />
      <StagesSection />
      <OurAdvantagesSection />
      <OurContactsSection />
      <Footer />
    </>
  );
}

export default MainPage;
