import { Link } from 'react-router-dom';
import footerLogo from '../../img/footer-logo.png';
import footerContactMailIco from '../../img/footer-contact-mail-ico.png';
import footerContactPhoneIco from '../../img/footer-contact-phone-ico.png';
import footerContactAdressIco from '../../img/footer-contact-adress-ico.png';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <img
        src={footerLogo}
        alt="Логотип ПроАвтоматика"
        className="footer__logo"
      />
      <div className="footer__card">
        <div className="footer__sections-links-container">
          <div className="footer__sections-links-container-one">
            <a className="footer__link" href="#purposeSection">
              Цель компании
            </a>
            <a className="footer__link" href="#weOfferSection">
              Мы предлагаем
            </a>
            <a className="footer__link" href="#prosSection">
              Плюсы внедрения АСУ
            </a>
            <a className="footer__link" href="#whereUsedSection">
              Где применяют АСУ
            </a>
          </div>
          <div className="footer__sections-links-container-two">
            <a className="footer__link" href="#stagesSection">
              Этапы работ по проекту
            </a>
            <a className="footer__link" href="#ourAdvantagesSection">
              Наши преимущества{' '}
            </a>
            <a className="footer__link" href="#ourContactsSection">
              Наши контакты{' '}
            </a>
          </div>
        </div>
        <div className="footer__card-contacts">
          <a
            className="footer__card-contact"
            href="mailto:info@automatika.pro"
            target="_blanck"
          >
            <img
              src={footerContactMailIco}
              alt="Красная иконка конверта"
              className="footer__card-contact-ico"
            />
            <p className="footer__card-contact-text">INFO@AUTOMATIKA.PRO</p>
          </a>
          <a
            className="footer__card-contact"
            href="tel:+78462210444"
            target="_blanck"
          >
            <img
              src={footerContactPhoneIco}
              alt="Красная иконка телефона "
              className="footer__card-contact-ico"
            />
            <p className="footer__card-contact-text">8 (846) 22-10-444</p>
          </a>
          <a
            className="footer__card-contact"
            href="https://2gis.ru/samara/search/%D0%B3.%20%D0%A1%D0%B0%D0%BC%D0%B0%D1%80%D0%B0%2C%20%D1%83%D0%BB.%20%D0%9D%D0%B5%D0%B2%D1%81%D0%BA%D0%B0%D1%8F%20%D0%B4.3%2C%20%D0%BE%D1%84%D0%B8%D1%81%20105?m=50.125832%2C53.210532%2F17.71"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={footerContactAdressIco}
              alt="Красная иконка геометки"
              className="footer__card-contact-ico"
            />
            <p className="footer__card-contact-text">
              443100, г. Самара, ул. Невская д.3, офис 105
            </p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
