import prosSectionBgFigureImg from '../../img/prosSection-bg-figure-img.png';
import prosSectionBgLogoImg from '../../img/prosSection-bg-logo-img.png';
import listPolygonImg from '../../img/list-polygon-img.png';
import './ProsSection.css';

function ProsSection() {
  return (
    <div className="prosSection" id="prosSection">
      <div className="prosSection__top-part">
        <h2 className="prosSection__top-part-title">Плюсы внедрения АСУ</h2>
        <p className="prosSection__top-part-text">
          Системы АСУ охватывают широкий спектр промышленных процессов:
          от&nbsp;управления оборудованием до&nbsp;полной автоматизации цехов
          и&nbsp;предприятий.
        </p>
        <p className="prosSection__top-part-text">
          Мы&nbsp;предоставляем наши системы на&nbsp;базе современных
          промышленных средств автоматизации и&nbsp;программного обеспечения.
        </p>
      </div>
      <div className="prosSection__bottom-part">
        <img
          src={prosSectionBgFigureImg}
          alt="Изображение серого вертикально-расположенного приямоугольника со срезанным правым верхним углом"
          className="prosSection__bottom-part-bgFigureImg"
        />
        <img
          src={prosSectionBgLogoImg}
          alt="Изображение затемненного срезанного слевой части логотипа ПроАвтоматика"
          className="prosSection__bottom-part-BgLogoImg"
        />
        <ul className="prosSection__bottom-part-list">
          <div className="prosSection__bottom-part-list-item-container">
            <img
              src={listPolygonImg}
              alt="Изображение белого полигона"
              className="prosSection__bottom-part-listPolygonImg"
            />
            <li className="prosSection__bottom-part-list-item">
              Повышение качества продукции
            </li>
          </div>
          <div className="prosSection__bottom-part-list-item-container">
            <img
              src={listPolygonImg}
              alt="Изображение белого полигона"
              className="prosSection__bottom-part-listPolygonImg"
            />
            <li className="prosSection__bottom-part-list-item">
              Повышение надежности системы
            </li>
          </div>
          <div className="prosSection__bottom-part-list-item-container">
            <img
              src={listPolygonImg}
              alt="Изображение белого полигона"
              className="prosSection__bottom-part-listPolygonImg"
            />
            <li className="prosSection__bottom-part-list-item">
              Снижение влияния человеческого фактора
            </li>
          </div>
          <div className="prosSection__bottom-part-list-item-container">
            <img
              src={listPolygonImg}
              alt="Изображение белого полигона"
              className="prosSection__bottom-part-listPolygonImg"
            />
            <li className="prosSection__bottom-part-list-item">
              Снижение времени простоя оборудования
            </li>
          </div>
          <div className="prosSection__bottom-part-list-item-container">
            <img
              src={listPolygonImg}
              alt="Изображение белого полигона"
              className="prosSection__bottom-part-listPolygonImg"
            />
            <li className="prosSection__bottom-part-list-item">
              Увеличение износостойкости оборудования
            </li>
          </div>
          <div className="prosSection__bottom-part-list-item-container">
            <img
              src={listPolygonImg}
              alt="Изображение белого полигона"
              className="prosSection__bottom-part-listPolygonImg"
            />
            <li className="prosSection__bottom-part-list-item">
              Контроль на всех этапах процесса
            </li>
          </div>
          <div className="prosSection__bottom-part-list-item-container">
            <img
              src={listPolygonImg}
              alt="Изображение белого полигона"
              className="prosSection__bottom-part-listPolygonImg"
            />
            <li className="prosSection__bottom-part-list-item">
              Контроль работы оборудования, предоставление документации
            </li>
          </div>
          <div className="prosSection__bottom-part-list-item-container">
            <img
              src={listPolygonImg}
              alt="Изображение белого полигона"
              className="prosSection__bottom-part-listPolygonImg"
            />
            <li className="prosSection__bottom-part-list-item">
              Снижение нагрузки на персонал
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default ProsSection;
