import purposeSectionImg from '../../img/purposeSection-img.png';
import purposeSectionPolygon from '../../img/purposeSection-polygon.png';
import './PurposeSection.css';

function PurposeSection() {
  return (
    <div className="purposeSection" id="purposeSection">
      <div className="purposeSection__container">
        <h2 className="purposeSection__title">Цель компании</h2>
        <p className="purposeSection__text">
          повышение эффективности вашего предприятия, улучшение качества
          выпускаемой продукции, создание условний для оптимальной эксплуатации
          любого производства.
        </p>
      </div>
      <div className="purposeSection__text-frames-container">
        <div className="purposeSection__text-frame-container">
          <div className="purposeSection__text-frame-big-poligon"></div>
          <div className="purposeSection__text-frame-small-poligon"></div>
          <p className="purposeSection__text-frame">
            Мы&nbsp;создаем платформы для управления предприятием
          </p>
        </div>
        <div className="purposeSection__text-frame-container-two">
          <div className="purposeSection__text-frame-big-poligon-two"></div>
          <div className="purposeSection__text-frame-small-poligon-two"></div>
          <p className="purposeSection__text-frame">
            Внедряем инновационные системы на&nbsp;ваше произодство
          </p>
        </div>
        <div className="purposeSection__text-frame-container">
          <div className="purposeSection__text-frame-big-poligon"></div>
          <div className="purposeSection__text-frame-small-poligon"></div>
          <p className="purposeSection__text-frame">
            Предлагаем оптимальные методы достижения результата
          </p>
        </div>
        <div className="purposeSection__text-frame-container-two">
          <div className="purposeSection__text-frame-big-poligon-two"></div>
          <div className="purposeSection__text-frame-small-poligon-two"></div>
          <p className="purposeSection__text-frame">
            Гарантированное качество выпускаемой продукции, точность процессов
          </p>
        </div>
      </div>
      <img
        src={purposeSectionImg}
        alt="Фото двух оражевых роботизированных станков во время работы"
        className="purposeSection__img"
      />
      <img
        src={purposeSectionPolygon}
        alt="Изображение бордового полигона"
        className="purposeSection__polygon"
      />
    </div>
  );
}

export default PurposeSection;
